import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";

import "./SidebarData.css";
import { IconContext } from "react-icons";
import Logo from "../Assets/Images/acer_logo_white_2.png";
import LogoGreen from "../Assets/Images/acer_logo_tab3.png";
import { DotMenu } from "./DotMenu";
import { createUseStyles } from "react-jss";
import cc from "classcat";
import { Drawer, ListItemIcon, ListItemText } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

const useStyles = createUseStyles({
  navbar: {
    backgroundColor: "black",
    height: "48px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },
  homePageNavbar: {
    backgroundColor: "black",
    height: "48px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
  },

  menuBars: {
    marginLeft: "2rem",
    fontSize: "18px",
    background: "none",
  },
  menuBarsHomepage: {
    marginLeft: "2rem",
    fontSize: "18px",
    background: "none",
    color: "#83b81a !important",
  },

  navMenu: {
    backgroundColor: "#fff",
    width: "250px",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: "-100%",
    transition: "550ms",
  },

  navMenuActive: {
    left: 0,
    transition: "350ms",
  },

  navMenuItems: {
    width: "100%",
  },

  navbarToggle: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "20px",
    alignItems: "center",
  },

  navbarLogo: {
    maxWidth: "150px",
    maxHeight: "105px",
  },
  navbarLogoHomepage: {
    maxWidth: "150px",
    maxHeight: "105px",
  },
});

const Navbar = ({user}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [isOpenItemsSubmenuOpen, setOpenItemsSubMenuOpen] = useState(true);
  const [isContentSubmenuOpen, setContentSubMenuOpen] = useState(true);
  const [isAdministrationSubmenuOpen, setAdministrationSubmenuOpen] =
    useState(false);

  const [isDocumentsSubmenuOpen, setDocumentsSubmenuOpen] = useState(true);
  const [isBusinessRulesOpen, setBusinessRulesOpen] = useState(true);
  console.log(open);
  const showSidebar = () => {
    setOpen(!open);
  };
  const handleOpenItemsClick = () => {
    setOpenItemsSubMenuOpen(!isOpenItemsSubmenuOpen);
  };

  const handleContentClick = () => {
    setContentSubMenuOpen(!isContentSubmenuOpen);
  };

  const handleAdministrationClick = () => {
    setAdministrationSubmenuOpen(!isAdministrationSubmenuOpen);
  };

  const handleDocumentsSubmenuOpen = () => {
    setDocumentsSubmenuOpen(!isDocumentsSubmenuOpen);
  };

  const handleBusinessRulesClick = () => {
    setBusinessRulesOpen(!isBusinessRulesOpen);
  };

  
  return (
    <>
      <IconContext.Provider
        value={{
          style: {
            color: "#fff",
          },
        }}
      >
        <div
          className={
            window.location.pathname === "/dashboard"
              ? styles.homePageNavbar
              : styles.navbar
          }
        >
          <div>
            <div>
              <Link to="#">
                <FaIcons.FaBars
                  onClick={showSidebar}
                  className={
                    window.location.pathname === "/dashboard"
                      ? styles.menuBarsHomepage
                      : styles.menuBarsHomepage
                  }
                />
              </Link>
            </div>

            <Drawer
              open={open}
              anchor={"left"}
              onClose={() => setOpen(false)}
              sx={{
                width: "300px",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  paddingTop: "20px",
                  width: "300px",
                  backgroundColor: "#fff",
                },
              }}
            >
              <li className={styles.navbarToggle} onClick={showSidebar}>
                <Link to="#" className={styles.menuBars}>
                  <AiIcons.AiOutlineClose style={{ color: "#5E6A71" }} />
                </Link>
              </li>
              <h2 style={{ paddingLeft: "20px", paddingBottom: "10px" }}>
                Menu
              </h2>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "#fff",
                  color: "#5E6A71",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <Link
                  to="/dashboard"
                  style={{ textDecoration: "none", color: "#5E6A71" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <OtherHousesOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </Link>
                <ListItemButton onClick={handleOpenItemsClick}>
                  <ListItemIcon>
                    <AssignmentTurnedInOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Open Items" />
                  {isOpenItemsSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={isOpenItemsSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/orders/view/open"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <AccountTreeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Order Chains" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isOpenItemsSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/suborders/view/open"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ShoppingCartOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isOpenItemsSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/shipments/view/open"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <LocalShippingOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Shipments" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isOpenItemsSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/documents/view/open"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ArticleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Documents" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isOpenItemsSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/invoices/view/open"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <PaidOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Invoices" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <ListItemButton onClick={handleContentClick}>
                  <ListItemIcon>
                    <ListAltOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Content" />
                  {isContentSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/orders"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <AccountTreeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Order Chains" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/suborders"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ShoppingCartOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/shipments"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <LocalShippingOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Shipments" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/documents"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ArticleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Documents" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/invoices"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <PaidOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Invoices" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Collapse
                  in={isContentSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/emails"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <EmailOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Emails" />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                <Link
                  to="/reports"
                  style={{ textDecoration: "none", color: "#5E6A71" }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <AnalyticsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reporting" />
                  </ListItemButton>
                </Link>
                <ListItemButton onClick={handleAdministrationClick}>
                  <ListItemIcon>
                    <AdminPanelSettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Administration" />
                  {isAdministrationSubmenuOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>

                <Collapse
                  in={isAdministrationSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <Link
                      to="/adminstration/masterdata"
                      style={{ textDecoration: "none", color: "#5E6A71" }}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ArrowForwardOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Master Data" />
                      </ListItemButton>
                    </Link>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={handleDocumentsSubmenuOpen}
                    >
                      <ListItemIcon>
                        <ArrowForwardOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Documents" />
                      {isDocumentsSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={isDocumentsSubmenuOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/doctypes"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Document Types & Templates" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
                <Collapse
                  in={isAdministrationSubmenuOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={handleBusinessRulesClick}
                    >
                      <ListItemIcon>
                        <ArrowForwardOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Business Rules" />
                      {isBusinessRulesOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={isBusinessRulesOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/rules/availability"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Availability Rules" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Collapse
                      in={isDocumentsSubmenuOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/legrules"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Leg Rules" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Collapse
                      in={isBusinessRulesOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/rules/classification"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Classification Rules" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Collapse
                      in={isBusinessRulesOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/rules/extraction"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Extraction Rules" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                    <Collapse
                      in={isBusinessRulesOpen}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <Link
                          to="/adminstration/rules/matching"
                          style={{ textDecoration: "none", color: "#5E6A71" }}
                        >
                          <ListItemButton sx={{ pl: 11 }}>
                            <ListItemText primary="Matching Rules" />
                          </ListItemButton>
                        </Link>
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
              </List>
            </Drawer>
          </div>
          <div>
            {window.location.pathname === "/dashboard" ? (
              <img
                src={LogoGreen}
                alt="logo"
                className={styles.navbarLogoHomepage}
              />
            ) : (
              <img src={LogoGreen} alt="logo" className={styles.navbarLogoHomepage} />
            )}
          </div>
          <div>
            {/* <DotMenu user = {user} /> */}
            <DotMenu />

          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
