import React, { Profiler, useCallback, useMemo, useRef } from "react";
import { Grid, Typography, Box, Item} from '@mui/material';
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import UserContext from "../UserContext";
import { useContext } from "react";
import axios from "axios";
import { DocumentStatus } from "./CustomizeChip";
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { set } from "react-hook-form";
import { Center } from "@chakra-ui/react";
import Xarrow from "react-xarrows";
import * as CountryFlagIcons from 'country-flag-icons/react/3x2'
import { hasFlag } from 'country-flag-icons'


const useStyles = createUseStyles({
  titleContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },

  pageTitle: {
    padding: "0px 0px 0px 10px",
    fontSize: "32px",
    marginBottom: 0,
  },

  writtenElem: {
    fontSize: "25px"
  },

  subHeaderContainer: {
    paddingLeft: "13px",
  },
  descriptionContainer: {
    //flexDirection: "column",
    //paddingLeft: "5px",
    display:"flex",
    alignItems:'center',
    padding: "25px 50px"
  },
  separator: {
    position: 'absolute',
    top:'0',
    bottom:'0',
    margin: 'auto',
    width: "85%",
    height: "5px",
    background: "#E2E0E2"
  },
  separator2:{
    height: '5px',
    width: "85%",
    backgroundColor: '#E2E0E2',
    flexGrow: '1',
  },
  separtorVerical:{
    borderRight: '5px dashed #E2E0E2',
    height: '100%',
  },
  iconsVisualisation:{
    margin:"0 10px",
    fontSize : "80"
  },
  row:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  leftCase:{
    display:'flex',
    alignItems:'center',
    width: '100%',
    //rightPadding: '0px',
    //float: 'left',
    //padding: '20px',
    //border: '2px solid red',
    //justifyContent:'space-between',
  },
  all:{
    display:'flex',
    justifyContent:'center',
    border: '3px solid #fff',
    //padding: '20px',
    //background:'blue'
  },
  country:{
    display:'flex',
    // justifyContent:'center',
    // border: '3px solid #fff',
    height: '100%',
    width: "100%",
    backgroundColor: 'black',
    color: "white"
  }
});


const Country = (props) => {
  if (props.unicode === "-" || hasFlag(props.unicode) === false) {
    return (<div>-flag-</div>);
  }
  const CountryComp = CountryFlagIcons[props.unicode];
  console.log(props.unicode)
  console.log(CountryComp);
  return (
    <CountryComp></CountryComp>
  );
}


const OrderVisualizationComponent = ({ orderId, leg }) => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const [invoice_1, setInvoice_1] = useState(["-","-"]);
  const [waybill_1, setWaybill_1] = useState(["-","-"]);
  const [importdoc_1, setImportdoc_1] = useState(["-","-"]);
  const [exportdoc_1, setExportdoc_1] = useState(["-","-"]);
  const [invoice_2, setInvoice_2] = useState(["-","-"]);
  const [waybill_2, setWaybill_2] = useState(["-","-"]);
  const [proof_of_delivery_2, setProof_of_delivery_2] = useState([["-","-"]]);
  const [leg2, setLeg2] = useState([""]);
  const [shipment_1, setShipment_1] = useState("-")
  const [shipment_2, setShipment_2] = useState("-")
  const [node_1, setNode_1] = useState("-")
  const [node_2, setNode_2] = useState("-")
  const [node_3, setNode_3] = useState("-")

  /*const fetchVisualisationData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${user.user.signInUserSession.accessToken.jwtToken}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    console.log('requestOptions : ', requestOptions)
    fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/order/${orderId}/visualisation`,
      requestOptions
    ).then((resp)=>console.log('test resp visual : ', resp))
    
  }*/

  const fetchVisualisationData = async () => {
    try {
      const token = user.user.signInUserSession.accessToken.jwtToken;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        Authorization: `Bearer ${token}`,
      };
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/item/order/${orderId}/visualization`, requestOptions);
      const data = await response.json();
      console.log('data: ', data);
      setInvoice_1(data[0]["1"]["Invoice"] || ["-", "-"])
      setWaybill_1(data[0]["1"]["Waybill"] || ["-", "-"])
      setImportdoc_1(data[0]["1"]["Import Document"] || ["-", "-"])
      setExportdoc_1(data[0]["1"]["Export Document"] || ["-", "-"])
      setInvoice_2(data[0]["2"]["Invoice"] || ["-", "-"])
      setWaybill_2(data[0]["2"]["Waybill"] || ["-", "-"])
      setProof_of_delivery_2(data[0]["2"]["Proof of Delivery"] || ["-", "-"])
      setShipment_1(data[0]["1"]["Shipment"] || "-")
      setShipment_2(data[0]["2"]["Shipment"] || "-")
      setNode_1(data[0]["node1"] || "-")
      setNode_2(data[0]["node2"] || "-")
      setNode_3(data[0]["node3"] || "-")
      if (data[0]["node3"] === "<Not defined>") {
        console.log('VISUAL FOR LEG 1');
        setLeg2("None")
      } else if (data[0]["node1" === "<Not defined>"]) {
        console.log('VISUAL FOR LEG 2');
      } else {
        console.log("VISUAL FOR LEG 1 AND LEG 2");
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(()=>{
    fetchVisualisationData();
  },[])

  let flag = false

  return (
    <>
    <div className={styles.subHeaderContainer}>
      <div className={styles.titleContainer}>
          <div className={styles.titleIcon}>
            <SchemaOutlinedIcon
              fontSize="large"
              sx={{
                color: "#83b81a",
              }}
            />
          </div>
          <h1 className={styles.pageTitle}>Visualization</h1>
        </div>
        {flag ?
        <div className={styles.all}>
          <div className={styles.row} style={{maxWidth:"70%"}}>
            <div className={styles.leftCase}>
              <FactoryOutlinedIcon style={{ margin:"0 50px",fontSize : "80"}}/>
              <span className={styles.separator2}></span>
              <WarehouseOutlinedIcon style={{marginLeft:"50px",fontSize : "80"}}/>
            </div>
            <div className={styles.leftCase} style={{height:'200px', display:'flex', justifyContent:'space-between'}}>
              <div/>
              <Grid item direction="column" alignItems="center">
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Invoice {invoice_1[0]}/{invoice_1[1]}</Typography>
              </Grid>
              <Grid item direction="column" alignItems="center">
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Waybill {waybill_1[0]}/{waybill_1[1]} </Typography>
              </Grid>
              <div />
              <div className={styles.separtorVerical} style={{position:'relative', right:'10%'}}></div>
            
            </div>
            <div className={styles.leftCase} style={{justifyContent:'flex-end'}}>
              <Grid item direction="column" alignItems="center" sx={{position:'relative',right:"55px"}}>
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Import Doc {importdoc_1[0]}/{importdoc_1[1]}</Typography>
              </Grid>
            </div>
          </div>

          <div className={styles.row} style={{display:leg2}}>
            <div className={styles.leftCase}>
              <span className={styles.separator2}></span>
              <WarehouseOutlinedIcon style={{margin:"0 50px",fontSize : "80"}}/>
            </div>
            <div className={styles.leftCase} style={{display:'flex', justifyContent:'space-between', height:'200px'}}>
              <div className={styles.separtorVerical} style={{position:'relative', left:'5%'}}></div>
              <Grid item direction="column" alignItems="center">
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Invoice {invoice_2[0]}/{invoice_2[1]}</Typography>
              </Grid>
              <Grid item direction="column" alignItems="center">
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Waybill {waybill_2[0]}/{waybill_2[1]}</Typography>
              </Grid>
              <div className={styles.separtorVerical} style={{margin:'0 75px'}}></div>
            </div>
            <div className={styles.leftCase} style={{display:'flex', justifyContent:'space-between'}}>
              <Grid item direction="column" alignItems="center" sx={{}}>
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Export Doc {exportdoc_1[0]}/{exportdoc_1[1]}</Typography>
              </Grid>
              <Grid item direction="column" alignItems="center" sx={{}}>
                <DescriptionOutlinedIcon style={{fontSize : "80"}}/>
                <Typography variant="subtitle1">Proof of Delivery {proof_of_delivery_2[0]}/{proof_of_delivery_2[1]}</Typography>
              </Grid>
            </div>
          </div>  

        </div> : 
        <div>
          <span></span>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_1}></Country></div>{node_1}</center></div></Grid>
            <Grid item xs={5.05}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_2}></Country></div>{node_2}</center></div></Grid>
            <Grid item xs={5.05}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} style={{background: ""}}><center><div><Country unicode={node_3}></Country></div>{node_3}</center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
            <Grid item xs={0.5}><div id="origin"><center><FactoryOutlinedIcon style={{fontSize : "80"}}/></center></div></Grid>
            <Grid item xs={5.05}><div>
              <Xarrow 
              start="origin" 
              end="transit" 
              labels={<div className={styles.writtenElem} style={{background: "#E2E0E2"}}>Shipment: {shipment_1}</div>}
              lineColor="#E2E0E2" 
              headColor="#E2E0E2"/></div></Grid>
            <Grid item xs={0.5}><div id="transit"><center><WarehouseOutlinedIcon style={{fontSize : "80"}}/></center></div></Grid>
            <Grid item xs={5.05}><div>
              <Xarrow 
              start="transit" 
              end="end" 
              labels={<div className={styles.writtenElem} style={{background: "#E2E0E2"}}>Shipment: {shipment_2}</div>}
              lineColor="#E2E0E2" 
              headColor="#E2E0E2"/></div></Grid>
            <Grid item xs={0.5}><div id="end"><center><WarehouseOutlinedIcon style={{fontSize : "80"}}/></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5.25}><div style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.5}><div id="importMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={0.5}><div id="exportMarker" style={{background: "", height: "1px"}}></div></Grid>
            <Grid item xs={5.25}><div style={{height: "1px"}}></div></Grid>
          </Grid>
          <div style={{background: "", height: "40"}}></div>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={2.25}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_1[0]}/{invoice_1[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_1[0]}/{waybill_1[1]}</p></center></div></Grid>
            <Grid item xs={4.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Invoice{"\n"}{invoice_2[0]}/{invoice_2[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Waybill{"\n"}{waybill_2[0]}/{waybill_2[1]}</p></center></div></Grid>
            <Grid item xs={2.25}><div style={{background: "", height: "40px"}}></div></Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={5.25}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div id="importDoc" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Import{"\n"}{importdoc_1[0]}/{importdoc_1[1]}</p></center></div></Grid>
            <Grid item xs={0.5}><div style={{background: "", height: "40px"}}></div></Grid>
            <Grid item xs={0.5}><div id="exportDoc" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Export{"\n"}{exportdoc_1[0]}/{exportdoc_1[1]}</p></center></div></Grid>
            <Grid item xs={4.55}><div style={{background: "", height: "40px"}}></div></Grid>            
            <Grid item xs={0.5}><div id="proofOfDelivery" className={styles.writtenElem} ><center><DescriptionOutlinedIcon style={{fontSize : "80"}}/><p style={{whiteSpace: "pre-line"}}>Proof of Delivery{"\n"}{proof_of_delivery_2[0]}/{proof_of_delivery_2[1]}</p></center></div></Grid>
            <Grid item xs={0.2}><div style={{background: "#E2E0E2", height: "0px"}}></div></Grid>
          </Grid>
          <div>
              <Xarrow 
              start="importMarker" 
              end="importDoc" 
              lineColor="#E2E0E2" 
              headColor="#E2E0E2"
              dashness={true}
              showHead={false}/></div>
            <div>
              <Xarrow 
              start="exportMarker" 
              end="exportDoc" 
              lineColor="#E2E0E2" 
              headColor="#E2E0E2"
              dashness={true}
              showHead={false}/></div>
            <div>
              <Xarrow 
              start="end" 
              end="proofOfDelivery" 
              lineColor="#E2E0E2" 
              headColor="#E2E0E2"
              dashness={true}
              showHead={false}/></div>
          <span></span>
        </div>  
        }
      </div>
    </>
  );
};

export default OrderVisualizationComponent;
