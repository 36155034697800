import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";

const UserContext = createContext();

export function UserProvider({ children }) {
  Amplify.configure({
    // Making use of the magic of AWS Amplify to do some heavy lifting.
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AWS_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USERPOOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_2,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_PASSWORD_AUTH",

      // OPTIONAL - Hosted UI configuration !!! REACT_APP_COGNITO_DOMAIN as in SUBDOMAIN, so NOT THE FULL DOMAIN !!!
      oauth: {
        domain: `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
        scope: [
          "User-REST-API@Resource-Server/User-REST-API.ReadOnly@Scope",
          "openid",
        ],
        redirectSignIn: "https://acer-altregion-dms.hlxc.be/dashboard",
        redirectSignOut: "https://acer-altregion-dms.hlxc.be/",
        // redirectSignIn: "http://localhost:3000/dashboard",
        // redirectSignOut: "http://localhost:3000/",
        responseType: "code", // or 'token' for implicit grant flow, note that REFRESH token will only be generated when the responseType is code
      },
    },
  });

  // Use of state hooks
  // A Hook is a special function that lets you “hook into” React features.
  // For example, useState is a Hook that lets you add React state to function components.
  // When would I use a Hook?
  // If you write a function component and realize you need to add some state to it,
  // previously you had to convert it to a class.
  // Now you can use a Hook inside the existing function component.

  // useState declares a state variable, e.g. a variable that is preserved between function calls.
  // The argument of useState is the INITIAL STATE of this state variable.
  // useState return the CURRENT STATE and the functionthat updates it.
  const [user, setUser] = useState();
  const [apiResponse, setApiResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // An asynchronous function that sets the 'user' state variable to the current authenticated user.
  async function getCurrentUser() {
    console.log('get current user')
    try {
      const authUser = await Auth.currentAuthenticatedUser(); //fetches the authenticated user
      // console.log(JSON.stringify(authUser)); //logs the authenticatedUser infos into the console!
      setUser(authUser); // Update the state variable
      setIsLoading(false);
    } catch (e) {
      console.log("error happened", e); // if no authenticated user is detected:

      setUser(null); // Update the 'user' state variable to reflect this case
      setIsLoading(false); // Update the 'isLoading' state variable to reflect this case
    }

    // const authUser = await Auth.currentAuthenticatedUser();
    // setUser(authUser);
    console.log("###### THIS IS THE USER DATA ######");
    console.log(user);
  }

  // The Effect Hook lets you perform side effects in function components
  // Effect hooks let you use state and other React features without writing a class.
  // useEffect(<function>, <dependency>)
  useEffect(() => {
    
    getCurrentUser();
    // handleCallProtectedMethod1();
  },[]);

  // The function responsible for handling the protected API call
  async function handleCallProtectedMethod1(event) {
    // event.preventDefault(); // Lets the user stay on the page and not get redirected to the API's URL.
    // @ts-ignore
    const accessToken = user["signInUserSession"]["accessToken"]["jwtToken"]; // Fetches the needed field from the authenticated user data.
    const headers = {
      // Construct a API authorization header from the previously fetched data.
      Authorization: accessToken,
    };

    console.log("===== this is the access token =====");
    console.log(accessToken);

    const apiResp = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${process.env.REACT_APP_API_GATEWAY_RESOURCE_1}`,
      { headers }
    ); // Make an authenticated API call.
    setApiResponse(JSON.stringify(apiResp.data)); // Updates the 'apiResponse' state variable with the API response
  }

  // The function responsible for handling the protected API call
  async function handleCallProtectedMethod2(event) {
    event.preventDefault(); // Lets the user stay on the page and not get redirected to the API's URL.
    // @ts-ignore
    const accessToken = user["signInUserSession"]["accessToken"]["jwtToken"]; // Fetches the needed field from the authenticated user data.
    const headers = {
      // Construct a API authorization header from the previously fetched data.
      Authorization: accessToken,
    };

    const apiResp = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${process.env.REACT_APP_API_GATEWAY_RESOURCE_2}`,
      { headers }
    ); // Make an authenticated API call.
    setApiResponse(JSON.stringify(apiResp.data)); // Updates the 'apiResponse' state variable with the API response
  }

  // If the user is not authenticated, redirect to landing page.
  // if (!user && !isLoading) {
  //   return <Navigate to="/" replace={true} />; // Landing page details are located at App.js
  // }

  console.log(process.env.REACT_APP_USERPOOL_ID);
  // console.log(isLoggedIn)
  // if (user) {
  //   setIsLoggedIn(true);
  // }
  //   if (!isLoading) {
  //     console.log(user.signInUserSession.accessToken.jwtToken);
  //   } else if (user === null) {
  //     console.log("user is not available");
  //   }
  console.log("=====this is the state of the user ======");
  if (user) {
    console.log(true);
  } else {
    console.log(false);
  }

  return (
    <UserContext.Provider value={{ user, isLoading }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
