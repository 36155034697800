import React, { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import { Navigate } from "react-router-dom";
import axios from "axios";
import "./App.css";
import Navbar from "./Components/Navbar";
import Orders from "./Pages/Orders";
import Suborders from "./Pages/Suborders";
import Shipments from "./Pages/Shipments";
import Documents from "./Pages/Documents";
import Emails from "./Pages/Emails";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import OpenItemsOrders from "./Pages/OpenItemsOrders";
import OpenItemsDocuments from "./Pages/OpenItemsDocuments";
import OpenItemsShipments from "./Pages/OpenItemsShipments";
import OpenItemsSuborders from "./Pages/OpenItemsSuborders";
import Invoices from "./Pages/Invoices";
import Reports from "./Pages/Reports";
import MasterData from "./Pages/MasterData";
import DocumentTypesAndTemplates from "./Pages/DocumentTypesAndTemplates";
import DocumentTemplates from "./Pages/DocumentTemplates";
import AvailabilityRules from "./Pages/AvailabilityRules";
import ClassificationRules from "./Pages/ClassificationRules";
import ExtractionRules from "./Pages/ExtractionRules";
import MatchingRules from "./Pages/MatchingRules";
import Document from "./Pages/Document";
import Order from "./Pages/Order";
import Invoice from "./Pages/Invoice";
import Shipment from "./Pages/Shipment";
import Suborder from "./Pages/Suborder";
import OpenItemsInvoices from "./Pages/OpenItemsInvoices";
import LegRules from "./Pages/LegRules";
import Login from "./Pages/Login";
import { UserProvider } from "./UserContext";

function App() {
  Amplify.configure({
    // Making use of the magic of AWS Amplify to do some heavy lifting.
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AWS_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USERPOOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID_2,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_PASSWORD_AUTH",

      // OPTIONAL - Hosted UI configuration !!! REACT_APP_COGNITO_DOMAIN as in SUBDOMAIN, so NOT THE FULL DOMAIN !!!
      oauth: {
        domain: `${process.env.REACT_APP_COGNITO_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
        scope: [
          "User-REST-API@Resource-Server/User-REST-API.ReadOnly@Scope",
          "openid",
        ],
        redirectSignIn: "https://acer-altregion-dms.hlxc.be/dashboard",
        redirectSignOut: "https://acer-altregion-dms.hlxc.be/",
        // redirectSignIn: "http://localhost:3000/dashboard",
        // redirectSignOut: "http://localhost:3000/",
        responseType: "code", // or 'token' for implicit grant flow, note that REFRESH token will only be generated when the responseType is code
      },
    },
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // Use of state hooks
  // A Hook is a special function that lets you “hook into” React features.
  // For example, useState is a Hook that lets you add React state to function components.
  // When would I use a Hook?
  // If you write a function component and realize you need to add some state to it,
  // previously you had to convert it to a class.
  // Now you can use a Hook inside the existing function component.

  // useState declares a state variable, e.g. a variable that is preserved between function calls.
  // The argument of useState is the INITIAL STATE of this state variable.
  // useState return the CURRENT STATE and the functionthat updates it.
  const [user, setUser] = useState();
  const [apiResponse, setApiResponse] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // An asynchronous function that sets the 'user' state variable to the current authenticated user.
  async function getCurrentUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser(); //fetches the authenticated user
      // console.log(JSON.stringify(authUser)); //logs the authenticatedUser infos into the console!
      setUser(authUser); // Update the state variable
      setIsLoading(false);
      // setIsLoggedIn(true);
    } catch (e) {
      console.log("error happened", e); // if no authenticated user is detected:

      setUser(null); // Update the 'user' state variable to reflect this case
      setIsLoading(false); // Update the 'isLoading' state variable to reflect this case
    }
  }

  // The Effect Hook lets you perform side effects in function components
  // Effect hooks let you use state and other React features without writing a class.
  // useEffect(<function>, <dependency>)
  useEffect(() => {
    getCurrentUser();
    // handleCallProtectedMethod1();
  }, []);

  // The function responsible for handling the protected API call
  async function handleCallProtectedMethod1(event) {
    // event.preventDefault(); // Lets the user stay on the page and not get redirected to the API's URL.
    // @ts-ignore
    const accessToken = user["signInUserSession"]["accessToken"]["jwtToken"]; // Fetches the needed field from the authenticated user data.
    const headers = {
      // Construct a API authorization header from the previously fetched data.
      Authorization: accessToken,
    };

    console.log("===== this is the access token =====");
    console.log(accessToken);

    const apiResp = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${process.env.REACT_APP_API_GATEWAY_RESOURCE_1}`,
      { headers }
    ); // Make an authenticated API call.
    setApiResponse(JSON.stringify(apiResp.data)); // Updates the 'apiResponse' state variable with the API response
  }

  // The function responsible for handling the protected API call
  async function handleCallProtectedMethod2(event) {
    event.preventDefault(); // Lets the user stay on the page and not get redirected to the API's URL.
    // @ts-ignore
    const accessToken = user["signInUserSession"]["accessToken"]["jwtToken"]; // Fetches the needed field from the authenticated user data.
    const headers = {
      // Construct a API authorization header from the previously fetched data.
      Authorization: accessToken,
    };

    const apiResp = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${process.env.REACT_APP_API_GATEWAY_RESOURCE_2}`,
      { headers }
    ); // Make an authenticated API call.
    setApiResponse(JSON.stringify(apiResp.data)); // Updates the 'apiResponse' state variable with the API response
  }

  async function handleSignOut(event) {
    event.preventDefault(); // Lets the user stay on the page and not get redirected to the signout's URL.
    try {
      await Auth.signOut(); // Signs the user out.
    } catch (error) {
      console.log("error signing out: ", error); // If something went wrong, log it.
    }
    // @ts-ignore
    setUser(null); // Update the 'user' state variable to reflect this case
    setIsLoading(false); // Update the 'isLoading' state variable to reflect this case
  }

  // If the user is not authenticated, redirect to landing page.
  // if (!user && !isLoading) {
  //   return <Navigate to="/" replace={true} />; // Landing page details are located at App.js
  // }

  console.log(process.env.REACT_APP_USERPOOL_ID);
  // console.log(isLoggedIn)
  // if (user) {
  //   setIsLoggedIn(true);
  // }
  // if (!isLoading) {
  //   console.log(user.signInUserSession.accessToken.jwtToken);
  // }

  return (
    <>
      {user ? (
        <UserProvider>
          <Router>
            {/* <Navbar user={user} /> */}
            <Navbar />

            <Routes>
              <Route exact path="/dashboard" element={<Home />} />
              <Route path="/emails" element={<Emails />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/suborders" element={<Suborders />} />
              <Route path="/shipments" element={<Shipments />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/orders/view/open" element={<OpenItemsOrders />} />
              <Route
                path="/suborders/view/open"
                element={<OpenItemsSuborders />}
              />
              <Route
                path="/documents/view/open"
                element={<OpenItemsDocuments />}
              />
              <Route
                path="/shipments/view/open"
                element={<OpenItemsShipments />}
              />
              <Route
                path="/invoices/view/open"
                element={<OpenItemsInvoices />}
              />
              <Route
                path="/adminstration/masterdata"
                element={<MasterData />}
              />
              <Route
                path="/adminstration/doctypes"
                element={<DocumentTypesAndTemplates />}
              />
              <Route path="/adminstration/legrules" element={<LegRules />} />
              <Route
                path="/adminstration/rules/availability"
                element={<AvailabilityRules />}
              />
              <Route
                path="/adminstration/rules/classification"
                element={<ClassificationRules />}
              />
              <Route
                path="/adminstration/rules/extraction"
                element={<ExtractionRules />}
              />

              <Route
                path="/adminstration/rules/matching"
                element={<MatchingRules />}
              />
              <Route path="documents/:documentId" element={<Document />} />
              <Route path="item/order/:orderId" element={<Order />} />
              <Route path="item/suborder/:suborderId" element={<Suborder />} />
              <Route path="item/invoice/:invoiceId" element={<Invoice />} />
              <Route path="item/shipment/:shipmentId" element={<Shipment />} />
            </Routes>
          </Router>
        </UserProvider>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
